import React from 'react';
import Layout from "../../components/layout";

const Bassendean = () => {
    return (
        <Layout>
            <h1>Burleigh</h1>
            <h2>Instructor</h2>
            <p>Sensei Mike Lehman <br/>
                Email: <a href="mailto:Mikel@warriormonks.org">Mikel@warriormonks.org</a></p>

            <p><strong>Class Times</strong><br/>
                Tuesday and Thursday:<br/>
                6.00pm-7.30pm</p>

            <p><strong>Prices</strong><br/>
                Adults $25/class<br/>
                Children $15/class</p>

            <p><strong>Location</strong><br/>
                Burleigh Scout Hall<br/>
                59 West Burleigh Rd,<br/>
                (via Gold Coast City Council Carpark)<br/>
                Burleigh Heads, QLD 4220 </p>

            <p>
                <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3519.8329643625116!2d153.44317487576208!3d-28.090637543448384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6b91039926382405%3A0xaa06a77d51be595b!2sConnection%20Carpark%2C%2059%20W%20Burleigh%20Rd%2C%20Burleigh%20Heads%20QLD%204220!5e0!3m2!1sen!2sau!4v1700058111520!5m2!1sen!2sau"
                    width="400" height="300" frameborder="0" title="burleigh"></iframe>
            </p>

        </Layout>);
}

export default Bassendean;
